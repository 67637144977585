import React, { Component } from "react";
import Menu from "./Menu";
import ProductPage from "./ProductPage";
import Login from "./Login";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Menu />
        <Routes>
          <Route index path="/" element={<ProductPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
