import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <div className="bg-light">
      <Container className="py-5">
        <Row>
          <Col>
            <h1 className="text-center my-5">Tech Product Name</h1>
            <p className="text-center my-5">
              This is a short description of the tech product. It should entice
              users to learn more about what it does.
            </p>
            <div className="text-center mb-5">
              <Button variant="primary" size="lg">
                Learn More
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={6} className="mb-3 mb-md-0">
            <Image src="https://via.placeholder.com/500x300" fluid />
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Feature 1</Card.Title>
                <Card.Text>
                  This is a description of a feature of the tech product. It
                  should be clear and concise, but also highlight the benefits
                  of using this feature.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <Card.Title>Feature 2</Card.Title>
                <Card.Text>
                  This is another description of a feature of the tech product.
                  It should also be clear and concise, and highlight the
                  benefits of using this feature.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Feature 3</Card.Title>
                <Card.Text>
                  This is a description of a third feature of the tech product.
                  It should also be clear and concise, and highlight the
                  benefits of using this feature.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <Card.Title>Feature 4</Card.Title>
                <Card.Text>
                  This is another description of a feature of the tech product.
                  It should also be clear and concise, and highlight the
                  benefits of using this feature.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Image src="https://via.placeholder.com/500x300" fluid />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center my-5">
              <Button variant="primary" size="lg">
                Sign Up
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
